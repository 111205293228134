import React, {Component} from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import CaseStudyListItem from 'components/case-studies/case-study-list-item'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import DropdownSelect from "../components/dropdowns/dropdown-select";
import DropdownMultiSelect from "../components/dropdowns/dropdown-multi-select";
import './case-studies.sass'

class CaseStudiesPage extends Component {

  constructor(props){
    super(props)

    let lists = this.buildLists(this.props.data.allCaseStudiesYaml.edges)

    this.state = {
      industry: "All Industries",
      area: "All Areas",
      technique: [],
      industries: lists["industries"],
      areas: lists["areas"],
      techniques: lists["techniques"],
      caseStudies: this.props.data.allCaseStudiesYaml.edges,
      firstClick: true
    }
  }

  buildLists = (allCaseStudies) => {
    const industrySet = new Set();
    const areaSet = new Set();
    const techniqueSet = new Set();

    const buildList = (list) => {
      let arr= [];

      list.forEach((name) => {
        arr.push({
          name: name,
          highlight: false
        })
      });

      arr.sort((a, b) => a.name.localeCompare(b.name));

      return arr;
    }

    industrySet.add("All Industries");
    areaSet.add("All Areas");

    allCaseStudies.forEach(({node}) => {
      industrySet.add(node.industry);
      areaSet.add(node.area);

      node.technique.forEach((techniueItem) => techniqueSet.add(techniueItem));
    });

    const industryList = buildList(industrySet);
    const areaList = buildList(areaSet);
    const techniqueList = buildList(techniqueSet);

    let lists = {
      industries: industryList,
      areas: areaList,
      techniques: techniqueList
    }

    return lists;
  }

  filterCaseStudies = (caseStudies, selected, name) => {
    let industry = name === "industry" ? selected : this.state.industry;
    let area = name === "area" ? selected : this.state.area;
    let techniques = name === "technique" ? selected : this.state.technique;

    const active_industries = this.state.industries.filter(item => item.highlight);
    const active_areas = this.state.areas.filter(item => item.highlight);
    const active_techniques = this.state.techniques.filter(item => item.highlight);

    if(!this.state.firstClick) {
      if (industry !== "All Industries" && active_industries.filter(item => item.name === industry).length === 0) {
        area = 'All Areas';
        techniques = [];
      } else if (area !== "All Areas" && active_areas.filter(item => item.name === area).length === 0) {
        industry = 'All Industries';
        techniques = [];
      } else if (name === "technique" && techniques.length !== 0 && active_techniques.filter(item => techniques.includes(item.name)).length !== techniques.length) {
        industry = 'All Industries';
        area = 'All Areas';
      }
    }

    const filterIndustry = (caseStudies) => {
      if (industry === "All Industries") {
        return caseStudies;
      } else {
        return caseStudies.filter((study) => study.node.industry === industry);
      }
    }

    const filterArea = (caseStudies) => {
      if (area === "All Areas") {
        return caseStudies;
      } else {
        return caseStudies.filter((study) => study.node.area === area);
      }
    }

    const filterTechnique = (caseStudies) => {
      if (techniques.length === 0) {
        return caseStudies;
      } else {
        let matchedStudies = [];

        caseStudies.forEach((study) => {
          let count = 0;
          let techCount = study.node.technique.length - 1;

          study.node.technique.forEach((item, i) => {
            techniques.forEach((selectedItems) => {
              if (selectedItems === item) {
                count++;
              }
            })

            if (i === techCount && count > 0) {
              study.node.count = count;
              matchedStudies.push(study);
            }
          })
        })

        matchedStudies.sort((a, b) => b.node.count - a.node.count);
        return matchedStudies;
      }
    }

    let industryMatches = filterIndustry(caseStudies);
    let areaMatches = filterArea(industryMatches);
    let techniqueMatches = filterTechnique(areaMatches);
    let lists = this.filterDropdowns(techniqueMatches, industry, area);

    this.setState({
      caseStudies: techniqueMatches,
      industry: industry,
      area: area,
      technique: techniques,
      industries: lists["industries"],
      areas: lists["areas"],
      techniques: lists["techniques"],
      firstClick: false
    })
  }

  filterDropdowns = (filteredCaseStudies, industry, area) => {
    let highlightIndustries = [];
    let highlightAreas = [];
    let highlightTechniques = [];
    let remainingTechniques = new Set();

    filteredCaseStudies.forEach(({node})=>{
      node.technique.forEach((techniqueItem) => {
        remainingTechniques.add(techniqueItem);
      })
    })

    this.state.industries.forEach((industryItem) => {
      let obj = {
        name: industryItem.name
      }

      // Only the active item and all industries can be visible in this state
      if(industry !== "All Industries") {
        if (industryItem.name === industry) {
          obj.highlight = true;
        } else {
          obj.highlight = false;
        }
      } else {
        obj.highlight = false;

        if (area !== 'All Areas') {
          filteredCaseStudies.forEach((study) => {
            if (study.node.industry === industryItem.name) {
              obj.highlight = true;
            }
          })
        }
      }

      highlightIndustries.push(obj);
    });

    this.state.areas.forEach((areaItem) => {
      let obj = {
        name: areaItem.name
      }

      // Only the active item and all industries can be visible in this state
      if(area !== "All Areas") {
        if (areaItem.name === area) {
          obj.highlight = true;
        } else {
          obj.highlight = false;
        }
      } else {
        obj.highlight = false;

        if (industry !== 'All Industries') {
          filteredCaseStudies.forEach((study) => {
            if (study.node.area === areaItem.name) {
              obj.highlight = true;
            }
          })
        }
      }

      highlightAreas.push(obj);
    });

    this.state.techniques.forEach((techniqueItem) => {
      let obj = {
        name: techniqueItem.name,
        highlight: false
      }

      if (!(industry === 'All Industries' && area === 'All Areas')) {
        remainingTechniques.forEach((remainingTechnique) => {
          if (remainingTechnique === techniqueItem.name)
            obj.highlight = true;
        });
      }

      highlightTechniques.push(obj);
    });

    let lists = {
      industries: highlightIndustries,
      areas: highlightAreas,
      techniques: highlightTechniques
    }

    return lists;
  }

  getCaseStudies = (caseStudies) => {
    if (caseStudies.length > 0) {
      return caseStudies.map((study, i) => {
        return (
          <div key={i} className="col-lg-4 col-md-6 col-12 px-4 mb-5">
            <CaseStudyListItem
              data={study.node}
              className="CaseStudyListItem--lg"
              index={i}/>
          </div>
        )
      })
    } else {
      return (
        <div className="col-12 px-4 mb-5">
          <h4>We're Sorry!</h4>
          <p>We can't seem to find any case studies that match your search. Please try other search terms.</p>
        </div>
      )
    }
  }

  dropdownChange = (selected, name) => {
    this.filterCaseStudies(this.props.data.allCaseStudiesYaml.edges, selected, name);
  }

  render () {
    return (
      <Layout bodyClass="k-reverse-header">
        <SEO
          title="AI / ML Case Studies | Real life practical ML use cases"
          description="Read about our case studies. From huge organisations like the NHS to small 6 man companies, Kortical can seriously fast track AI and ML delivery."
          keywords={['Kortical clients', 'AI usage examples', 'AI case studies', 'ML case studies', 'Artificial Intelligence in Business']}/>
        <div className="CaseStudies">
          <section className="CaseStudies__header k-bg--grey">
            <div className="container-fluid">
              <div className="d-flex k-row">
                <div className="col-12 px-4">
                  <h1 className="h1--new">Case Studies: AI Examples in Industry</h1>
                  <p>Discover how we have applied artificial intelligence to deliver high-value machine learning solutions, quicker and of better quality, than they were able to before.</p>
                  <p className="mb-5">Here you will find real world AI examples, exploring how businesses are leveraging data and AI to augment human intelligence in many industries, for example - retail, healthcare, banking and finance industry.</p>
                  <DropdownSelect
                    className="CaseStudies__search-industry mb-4"
                    name="industry"
                    list={this.state.industries}
                    activeItem={this.state.industry}
                    selected={this.dropdownChange} />
                  <DropdownSelect
                    className="CaseStudies__search-area mb-4"
                    name="area"
                    list={this.state.areas}
                    activeItem={this.state.area}
                    selected={this.dropdownChange} />
                  <DropdownMultiSelect
                    className="CaseStudies__search-technique mb-4"
                    name="technique"
                    placeholder="AI Techniques"
                    list={this.state.techniques}
                    activeItems={this.state.technique}
                    selected={this.dropdownChange} />
                </div>
              </div>
            </div>
          </section>
          <section className="CaseStudies__list">
            <div className="container-fluid">
              <div className="d-flex flex-wrap k-row">
                {this.getCaseStudies(this.state.caseStudies)}
              </div>
            </div>
          </section>
        </div>
        <GetInTouch/>
      </Layout>
    )
  }
}

export default CaseStudiesPage

export const pageQuery = graphql`
  query {
    allCaseStudiesYaml(
      sort: {
        fields: [order],
        order: ASC
      }
    ) {
      edges {
        node {
          path
          client
          title
          logo
          industry
          area
          technique
          hero
        }
      }
    }
  }
`
