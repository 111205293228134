import React from 'react'
import {Link} from "gatsby";
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import Image from 'components/image/image'
import './case-study-list-item.sass'

const getLogo = (data) => {
  return data.logo ? (
    <Image image_url={`clients/cs/${data.logo}`} alt={`${data.client} client logo`} />
  ) : (
    <h2 className="CaseStudyListItem__logo-text text-light">
      {data.client}
    </h2>
  )
}

const CaseStudyListItem = ({ data, ...props }) => (
  <article
    className={`
      CaseStudyListItem
      ${props.className ? props.className : ''}`}
      onClick={ () => sendClickedEvent(`CaseStudyListItem__${data.client}`) }
    >
    <Link
      to={data.path}
      className="mb-0">
      <div className="CaseStudyListItem__header">
        <div className="CaseStudyListItem__img">
          <Image image_url={data.hero} alt={`Background image for ${data.title}`} />
        </div>
        <div className="CaseStudyListItem__header-filter"></div>
        <div className="CaseStudyListItem__header-content d-flex">
          <div className="CaseStudyListItem__logo">
            {getLogo(data)}
          </div>
          <div className="CaseStudyListItem__header-hover">
            <div className="btn btn-yellow mb-0">Read more</div>
          </div>
        </div>
      </div>
    </Link>
    <h4 className="CaseStudyListItem__title">
      <Link
        to={data.path}
        className="mb-0">
        {data.title}
      </Link>
    </h4>

    <div className="k-tag-blue">{data.industry}</div>
    <div className="k-tag">{data.area}</div>
  </article>
)

export default CaseStudyListItem
