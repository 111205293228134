import React, {useEffect, useRef, useState} from 'react'

const DropdownSelect = ({className, name, list, activeItem, selected}) => {

  const [isActive, setActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setActive(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isActive])

  const toggleClass = () => {
    setActive(!isActive);
  }

  const buildDropdown  = () => {
    const splitArr = (arr, len) => {
      let chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    }

    const buildList = (items) => {
      return items.map((item, i) =>
        <li
          key={i}
          className={`DropdownSelect__list-item ${activeItem === item.name ? "active" : ""} ${item.highlight ? "highlight" : ""}`}
          onClick={() => itemClicked(item.name)}
        >
          {item.name}
        </li>
      )
    }

    const itemClicked = (item) => {
      selected(item, name)
    }

    const arrLen = list.length < 10 ? 3 : 4;
    const listsArr = splitArr(list, arrLen);

    return listsArr.map((listItem, i) =>
      <ul key={i} className="DropdownSelect__list">
        {buildList(listItem)}
      </ul>
    )
  }

  return (
    <div className={`DropdownSelect ${className ? className : ''}`} ref={ref}>
      <div
        className={`DropdownSelect__button ${isActive ? "active" : ""}`}
        onClick={toggleClass}
      >
        {activeItem}
      </div>
      <div className={`DropdownSelect__list-wrapper ${isActive ? "active" : ""}`}>{buildDropdown()}</div>
    </div>
  )
}

export default DropdownSelect
