import React, {useEffect, useRef, useState} from 'react'

const DropdownMultiSelect = ({ className, name, placeholder, list, activeItems, selected}) => {
  const [isActive, setActive] = useState(false);
  const count = activeItems.length;
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setActive(false);
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    }
  }, [isActive])

  const buildDropdown  = () => {
    const splitArr = (arr, len) => {
      let chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    }

    const buildList = (items) => {
      return items.map((item, i) =>
        <li
          key={i}
          className={`DropdownMultiSelect__list-item ${activeItems.filter((activeItem) => activeItem === item.name).length > 0 ? "active" : ""} ${item.highlight ? "highlight" : ""}`}
          onClick={() => itemClicked(item.name)}
        >
          {item.name}
        </li>
      )
    }

    const arrLen = list.length < 10 ? 4 : 5;
    const listsArr = splitArr(list, arrLen);

    return listsArr.map((list, i) =>
      <ul key={i} className="DropdownMultiSelect__list">
        {buildList(list)}
      </ul>
    )
  }

  const itemClicked = (item) => {
    if (activeItems.indexOf(item) === -1) {
      activeItems.push(item);
    } else {
      activeItems.forEach((activeItem, i)=>{
        if(activeItem === item)
          activeItems.splice(i, 1);
      })
    }

    selected(activeItems, name)
  }

  const toggleClass = () => {
    setActive(!isActive);
  }

  const clearSelection = () => {
    selected([], name);
  }

  return (
    <div className={`DropdownMultiSelect ${className ? className : ''}`} ref={ref}>
      <div
        className={`DropdownMultiSelect__button ${isActive ? "active" : ""}`}
        onClick={toggleClass}
      >
        {placeholder}{count > 0 ? ` (${count})` : ""}
      </div>
      <div className={`DropdownMultiSelect__list-wrapper ${isActive ? "active" : ""}`}>
        {buildDropdown()}
        <button
          className="DropdownMultiSelect__clear"
          onClick={clearSelection}>
          Clear
        </button>
      </div>
    </div>
  )
}

export default DropdownMultiSelect
